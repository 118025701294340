import React from 'react'
import { graphql } from 'gatsby'
import Link from '../utilities/link'
import CommonFooter from '../components/footer/commonFooter'
import SiteNavigation from '../components/header/siteNavigation'
import ProductHeader from '../components/header/productHeader'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '../pageContext'
import ElegantHeader from '../components/header/elegantHeader'
import Card from '../components/common/card'
import Img from 'gatsby-image'

const BlogHome = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const { t } = useTranslation()
  const { lang } = usePageContext();

  return (
    <>
      <Seo 
        title={t('blog.seoTitle')}
        description={t('blog.seoDescription')}
        seoImage={data.bg.childImageSharp.resize.src}
      />
      <SiteNavigation />
      <div className='pt-24 md:pt-36 pb-20 bg-gradient-to-r from-indigo-500 via-sky-500 to-emerald-500 relative'>
          <Img
          fluid={data.bg.childImageSharp.fluid}
          style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 0, // Ensure the image is behind the text content
          }}
          imgStyle={{
              objectFit: 'cover', // Cover the entire div area
              objectPosition: '50% 50%', // Center the image within the div
              opacity: 0.2, // Optional: Set the opacity if you want to see anything behind it
          }}
          // className="image-wrapper-height-auto"
          alt='CTA Electronics Blog'
          />
          <div className='max-w-7xl mx-auto px-16 md:px-24 xl:px-0 flex items-center z-10 relative'>
            <div className='md:w-2/3'>
                <h1 className="font-semibold text-white text-2xl md:text-4xl">
                  {t('blog.headerTitle')}
                </h1>
                <p className="mt-4 text-white font-semibold">
                  {t('blog.headerSubtitle')}
                </p>
            </div>
          </div>
        </div>
      {/* <ProductHeader
        imageOpacity="0.2"
        headerTitle={t('blog.headerTitle')}
        headerSubtitle={t('blog.headerSubtitle')}
        fluid={data.olivetti.childImageSharp.fluid}
      /> */}
        <div className="bg-slate-50">
          <div className='py-12 md:py-24 max-w-7xl mx-auto px-8 md:px-0'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16">
                {edges
                  .filter(({ node }) => {
                    return node.frontmatter.lang === lang
                  })
                  .map(({ node }) => {
                    const { title, headline, translatedPath, category, seoImage } = node.frontmatter
                    return (
                      <div className="bg-white rounded flex items-center">
                        <div className=' px-8 py-8'>
                          <div className="flex flex-col md:flex-row space-x-8">
                            <div className='md:w-1/3'>
                            <Img
                              fluid={seoImage.childImageSharp.fluid}
                              alt={title}
                              style={{ height: '100%', width: '100%' }}
                              imgStyle={{ objectFit: 'cover' }}
                            />
                            </div>
                            <div className='md:w-2/3'>
                              <div className='flex justify-start pb-4 pt-4 md:pt-0'>
                                <p className="px-4 text-white bg-gradient-to-r from-indigo-500 via-sky-500 to-emerald-500 font-semibold rounded-full">{category}</p> 
                              </div>
                              <Link to={`/${translatedPath}`} className="text-2xl font-semibold text-gray-700 hover:text-emerald-500">{title}</Link>
                              <p className='pt-4'>{headline}</p>
                              </div>
                            </div>
                        </div>
                      </div>
                    )
                  })}
            </div>
          </div>
        </div>

      <CommonFooter />
    </>
  )
}

export default BlogHome

export const blogHomeQuery = graphql`
  query BlogHomeIT {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      edges {
        node {
          frontmatter {
            lang
            path
            translatedPath
            title
            headline
            category
            seoImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    bg: file(relativePath: { eq: "contactpagebgfullhd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
